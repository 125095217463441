//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import Home from '@/view/home/index'
import AboutUs from '@/view/aboutUs/index'
import Game from '@/view/game/index'
import Career from '@/view/career/index'
import Publishing from '@/view/publishing/index'
import PricPage from '@/view/pric/index'
import TermsPage from '@/view/terms/index'

// import GameStatement from '../view/gameStatement/gs.vue'
// import ResponsibleGameplayPolicy from '../view/responsibleGameplayPolicy/rgp.vue'
// import SweepsRules from '../view/sweepsRules/sr.vue'

const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'home',
            path: '/home',// 首页
            component: Home,
        },
        {
            name:'game',
            path: '/game',// 游戏
            component: Game, 
        },
        {
            name:'publishing',
            path: '/publishing',
            component: Publishing, 
        },
        {
            name:'career',
            path: '/career',
            component: Career,
        },
        {
            name:'aboutus',
            path: '/aboutus',// 关于我们
            component: AboutUs,
        },
        {
            name:'pric',
            path: '/pric.html',
            component: PricPage,
        },
        {
            name:'terms',
            path: '/terms.html',
            component: TermsPage,
        },
        // {
        //     name:'unctihome',
        //     path: '/uncti/home',// 首页
        //     component: Home,
        // },
        // {
        //     name:'unctigame',
        //     path: '/uncti/game',// 游戏
        //     component: Game, 
        // },
        // {
        //     name:'unctipublishing',
        //     path: '/uncti/publishing',
        //     component: Publishing, 
        // },
        // {
        //     name:'uncticareer',
        //     path: '/uncti/career',
        //     component: Career,
        // },
        // {
        //     name:'unctiaboutus',
        //     path: '/uncti/aboutus',// 关于我们
        //     component: AboutUs,
        // },
        // {
        //     name:'unctipric',
        //     path: '/uncti/pric.html',
        //     component: PricPage,
        // },
        // {
        //     name:'unctiterms',
        //     path: '/uncti/terms.html',
        //     component: TermsPage,
        // },
        // {
        //     name:'unctipernyataan-permainan',
        //     path: '/uncti/pernyataan-permainan.html',
        //     component: GameStatement,
        // },
        // {
        //     name:'unctibertanggung_jawab',
        //     path: '/uncti/bertanggung_jawab.html',
        //     component: ResponsibleGameplayPolicy,
        // },
        // {
        //     name:'unctimenyapu-aturan',
        //     path: '/uncti/menyapu-aturan.html',
        //     component: SweepsRules,
        // },
        // {
        //     path: '/uncti/*',
        //     redirect: '/uncti/home',
        // },
        {
            path: '*',
            redirect: '/home',
        }
    ]
  })
router.beforeEach((to,from,next)=>{
    console.log(to.name);
    console.log(from.name);
    if (to.name != from.name) {
        next();
    }else{
        next(false);
    }
})

export default router
