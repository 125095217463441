<template>
  <div>
    <Header message="/publishing"></Header>
    <div class="publish_body">
      <div class="publish_declaration  wow animate__animated animate__zoomInDown">
        <div class="publish_declaration_up">
          <h2>PUBLISHING</h2>
          <h3>Create the next gaming sensation with us!</h3>
        </div>
        <div class="publish_declaration_cen">
          <div class="data_display">
            <div class="data_left">
              <img class="data_left_img" src="@/assets/images/download-svgrepo-com-1.png" />
              <div class="data_left_number">
                <p>1.5 Billion</p>
                <h3>Downloads</h3>
              </div>
            </div>
            <div class="data_center">
              <img class="data_left_img" src="@/assets/images/Group.png" />
              <div class="data_left_number">
                <p>#17 Asia</p>
                <h3>Publisher</h3>
              </div>
            </div>
            <div class="data_right">
              <img class="data_left_img" src="@/assets/images/swarm-request-svgrepo-com-1.png" />
              <div class="data_left_number">
                <p>9M+</p>
                <h3>Active Users</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="publish_declaration_down">
          <div class="studio_button">
          <strong>Submit your game</strong>
        </div>
        </div>
      </div>

      <div class="job_Introduction  wow animate__animated animate__fadeInDown">
        <div class="job_Introduction_tit">
          <p>How It Works?</p>
        </div>
        <div class="job_Introduction_box">
          <div class="job_Introduction_box1">
            <p class="job_Introduction_box_p1">Step 1</p>
            <img src="../../assets/images/step1.png" alt="">
            <p class="job_Introduction_box_p2">Contact Us</p>
          </div>
          <div class="job_Introduction_box1">
            <p class="job_Introduction_box_p1">Step 2</p>
            <img src="../../assets/images/step2.png" alt="">
            <p class="job_Introduction_box_p2">Free Testing Prototype</p>
          </div>
          <div class="job_Introduction_box1">
            <p class="job_Introduction_box_p1">Step 3</p>
            <img src="../../assets/images/step3.png" alt="">
            <p class="job_Introduction_box_p2">Signing Collab Agreement</p>
          </div>
          <div class="job_Introduction_box1">
            <p class="job_Introduction_box_p1">Step 4</p>
            <img src="../../assets/images/step4.png" alt="">
            <p class="job_Introduction_box_p2">Monetize and Publishing</p>
          </div>
          <div class="job_Introduction_box1">
            <p class="job_Introduction_box_p1">Step 5</p>
            <img src="../../assets/images/step5.png" alt="">
            <p class="job_Introduction_box_p2">Analyze Data</p>
          </div>
          <div class="job_Introduction_box1">
            <p class="job_Introduction_box_p1">Step 6</p>
            <img src="../../assets/images/step6.png" alt="">
            <p class="job_Introduction_box_p2">Iterations & Scaling</p>
          </div>
        </div>
      </div>

      <p class="our_success_stories_tit">Our Success Stories</p>
      <div class="our_success_stories ">
        <div class="oursuccessstories">
          <img src="../../assets/images/Publishing_Zombie Zest Veggie Warfare_550_321_1.png" alt="">
          <div>
            <div class="oursuccessstories_up">
              <p class="oursuccessstories_up_tit">Vegetables battle the undead in big warfare.</p>
            </div>
            <div class="oursuccessstories_down">
              <div class="show_game_up">
                <div class="show_game_up_left">
                  <img src="../../assets/images/Publishing_Zombie Zest Veggie Warfare_icon70_70_1.png" alt="">
                </div>
                <div class="show_game_up_right">
                  <h3 class="show_game_up_right_title">Zombie Zest: Veggie Warfare</h3>
                  <p class="show_game_up_right_p">
                    <span class="show_game_up_right_p_span">Space Shooting</span>
                    <i class="iconfont icon-icon-user"></i>
                    <span>100.000.000+</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="oursuccessstories">
          <img src="../../assets/images/Publishing_Fantasy Clash_550_321_2.png" alt="">
          <div>
            <div class="oursuccessstories_up">
              <p class="oursuccessstories_up_tit">Cutie creatures collide in epic battles.</p>
            </div>
            <div class="oursuccessstories_down">
              <div class="show_game_up">
                <div class="show_game_up_left">
                  <img src="../../assets/images/Publishing_Fantasy Clash_icon70_70_2.png" alt="">
                </div>
                <div class="show_game_up_right">
                  <h3 class="show_game_up_right_title">Fantasy Clash</h3>
                  <p class="show_game_up_right_p">
                    <span class="show_game_up_right_p_span">Space Shooting</span>
                    <i class="iconfont icon-icon-user"></i>
                    <span>100.000.000+</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="oursuccessstories">
          <img src="../../assets/images/Publishing_Fox Defender Homestead Heroes_zhanshitu550_321_3.png" alt="">
          <div>
            <div class="oursuccessstories_up">
              <p class="oursuccessstories_up_tit">Protect your homestead with fox heroes.</p>
            </div>
            <div class="oursuccessstories_down">
              <div class="show_game_up">
                <div class="show_game_up_left">
                  <img src="../../assets/images/Publishing_Fox Defender Homestead Heroes_icon70_70_3.png" alt="">
                </div>
                <div class="show_game_up_right">
                  <h3 class="show_game_up_right_title">Fox Defender Homestead Heroes</h3>
                  <p class="show_game_up_right_p">
                    <span class="show_game_up_right_p_span">Space Shooting</span>
                    <i class="iconfont icon-icon-user"></i>
                    <span>100.000.000+</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 无限滚动卡片区域 -->
      <div class="scroll_bg">
        <div class="scroll_bg_title">Our Partners</div>
        <div style="padding-bottom: .5208rem;">
          <vue-seamless-scroll :data="images2" :class-option="optionLeft" class="seamless-warp2">
            <ul class="item">
              <li v-for="item in images2" :key="item">
                <img class="scrollimg" :src="item.url" alt="">
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'PUBLISHING',
  components: {
    Header,
    Footer,
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2
      }
    }
  },
  data() {
    return {
      images2: [
        { url: require("@/assets/topPartners/pangle.png") },
        { url: require("@/assets/topPartners/unity.png") },
        { url: require("@/assets/topPartners/vungle.png") },
        { url: require("@/assets/topPartners/tapjoy.png") },
        { url: require("@/assets/topPartners/ggads.png") },
        { url: require("@/assets/topPartners/ggadmob.png") },
        { url: require("@/assets/topPartners/unity.png") },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);
.publish_body{
  width: 100%;
}
.publish_declaration{
  margin-top: .3646rem;
  width: 100%;
  height: 2.6042rem;
  background-image: url(../../assets/images/publishing-bgh.png);
  opacity: .7;
}
.publish_declaration_up{
  width: 5.2083rem;
  margin: 0 auto;
  padding-top: .8854rem;
  h2{
    font-size: .0833rem;
    color: #FB9C2C;
  }
  h3{
    font-size: .2396rem;
    color: #fff;
  }
}

.data_display{
  width: 5.8854rem;
  margin: .7813rem auto ;
  display: flex;
  justify-content: space-evenly;
}
.data_left,.data_center,.data_right{
  display: flex;
  align-items: center
}
.data_left_img{
  width: .3333rem;
  height: .3333rem;
}
.data_left_number{
  margin-left: .1042rem;
  p{
    font-size: .1667rem;
    line-height: .3802rem;
    color: #FB9C2C;
    font-weight: bold;
  }
  h3{
    color: #B3B3B3;
    font-size: .1667rem;
    line-height: .2188rem;
  }
}
.publish_declaration_down{
  width: 4.8958rem;
  margin: 0 auto .2604rem;
  text-align: center;
  position: relative;
  top: -0.3646rem;
  left: 1.7708rem;
}
.studio_button{
  width: 1.3281rem;
  height: .3125rem;
  background-image: url(@/assets/images/button.png);
  background-size: cover;
  margin-top: .1563rem;
  text-align: center;
  line-height: .3125rem;
  font-size: .0938rem;
  color: #8F1209;
}


.job_Introduction{
  width: 5.9375rem;
  margin: 1.5625rem auto .2083rem;
  .job_Introduction_tit{
    p{
      text-align: center;
      font-size: .2083rem;
      color: #fff;
    }
  }
}
.job_Introduction_box{
  width: 5.9375rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.job_Introduction_box1{
  width: 1.4583rem;
  height: 1.1458rem;
  background-color: #222222;
  border-radius: .0521rem;
  padding-left: .0521rem;
  margin-right: .2083rem;
  margin-top: .1042rem;
  img{
    width: .4167rem;
    height: .4167rem;
  }
  p{
    line-height: .125rem;
    margin: .1042rem 0;
  }
  .job_Introduction_box_p1{
    color: #FB9C2C;
    font-size: .1042rem;
    line-height: .125rem;
  }
  .job_Introduction_box_p2{
    color: #fff;
    font-size: .125rem;
    line-height: .125rem;
  }
}
// 轮播样式
/** 滚动区域样式 */
.scroll_bg_title{
  color: #fff;
  font-size: .1667rem;
  padding: .1563rem 0;
}
.seamless-warp2 {
  overflow: hidden;
  height: .625rem;
  width: 7.8125rem;
  margin: .2604rem auto;
  padding: .2083rem;
  background-color: #000;
  border-radius: .0521rem;

  ul.item {
    width: 7.0313rem;
    list-style: none;
    li {
      float: left;
      margin-right: .5208rem;
    }
  }
}
.scrollimg{
  width: .4167rem;
  height: auto ;
}
.scroll_bg{
  border-top: 1px solid #383333;;
  margin: 250px 0 0 0;
  width: 100vw;
  height: 2.0833rem;
  padding: .2344rem 0;
  background-color: #000;
  text-align: center;
}
.our_success_stories_tit{
    font-size: .1875rem;
    line-height: .3229rem;
    color: #fff;
    margin: .2604rem;
    text-align: center;
  }
.our_success_stories{
  display: flex;
  width: 9.375rem;
  margin: 0 auto;
  justify-content: space-evenly;
  flex-wrap: wrap;
  
  .oursuccessstories{
    width: 2.8646rem;
    height: 2.6042rem;
    img{
      width: 100%;
      height: auto;
      border-radius: .0521rem;
    }
  }
}
.oursuccessstories_up_tit{
  font-size: .1302rem;
  line-height: .4167rem;
  color: #fff;
}
.show_game_up{
  display: flex;
  justify-content: space-evenly;
  padding-top: .1042rem;
}
.show_game_up_right{
  padding: 0 0 0 .0521rem;
  height: .3646rem;
}
.show_game_up_right_title{
  color: #fff;
  font-size: .0833rem;
  line-height: .1354rem;
  padding: 0 0 .0521rem;
}
.show_game_up_right_p>i{
  margin: 0 0 0 .1563rem;
  color: #ffffdd9e;
}
.show_game_up_right_p_span{
  font-size: .0729rem;
  color: #B3B3B3;
}
.show_game_up_right_p:last-child{
  font-size: .0729rem;
  color: #B3B3B3;
}
.show_game_up_left{
  img{
    width: .3906rem;
    height: .3906rem;
    border-radius: .0521rem;
  }
}
</style>